.controlPanel {
  position: absolute;
  bottom: 5px;
}

.controlButtons {
  display: inline-block;
  white-space: nowrap;
  z-index: 101 !important;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.controlButtonIcon {
  font-size: 35px;
  fill:  white;
}

.controlButtonIcon > path {
  stroke: black;
  stroke-width: 2px;
}

.datePicker {
    position: absolute;
    bottom: 5px;
    left: 100px;
    width: 80px;
    z-index: 101 !important;
    background-color: Transparent;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  
.MuiInputBase-input.MuiInput-input {
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px black;
}

@media not all and (pointer:coarse) {
  div[class^="MuiInput"] {
    font-size: x-large;
  }

  .datePicker {
    left: 110px;
    width: 130px;
  }

  .controlPanel {
    width: 95%;
    text-align: center;
  }

  .controlButtons {
    padding-left: 18px;
    padding-right: 18px;
  }  
}

@media (pointer:none), (pointer:coarse) {
  .controlPanel {
    position: absolute;
    left: 180px;
    bottom: 25px;
  }

  .controlButtons {
    padding-left: 10px;
    padding-right: 10px;
  }  

  .controlButtonIcon {
    font-size: 25px;
  }

  .datePicker {
    bottom: 25px;
  }
}