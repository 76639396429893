.layerSelector {
    position: absolute;
    bottom: 5px;
    left: 10px;
    z-index: 101 !important;
  }

.layerDescriptionButton {
    position: absolute;
    bottom: 40px;
    left: 5px;
    font-size: small;
    z-index: 100 !important;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;    
  }

@media not all and (pointer:coarse) {
  div[class^="MuiInput"] {
    font-size: x-large;
  }
}

@media (pointer:none), (pointer:coarse) {
  .layerSelector {
    bottom: 27px;
  }

  .layerDescriptionButton {
    bottom: 60px;
  }
}